.contacteContainer {
  height: 100vh;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FBF5E4;
  box-sizing: border-box;

  .contentContainer {
    max-width: 488px;
    width: 100%;
    max-height: 465px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-family: "Playfair Display", serif;
      font-style: italic;
      font-weight: 500;
      font-size: 100px;
      color: #083c00;
      text-align: center;
    }

    .secondBox {
      max-width: 488px;
      width: 100%;
      max-height: 283px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content:space-between ;
      align-items: center;

      .numberText {
        font-family: Inter, serif;
        font-weight: 400;
        font-size: 45px;
        line-height: 90%;
        text-transform: uppercase;
        text-align: center;
        color: #083c00;
      }

      .descriptionText {
        font-family: Inter, serif;
        font-weight: 400;
        font-size: 45px;
        line-height: 90%;
        text-transform: uppercase;
        text-align: center;
        color: #083c00;
        padding: 0 20px;
      }

      .nameText {
        font-family: Inter, serif;
        font-weight: 400;
        font-size: 40px;
        line-height: 90%;
        text-transform: uppercase;
        text-align: center;
        color: #083c00;
      }

      .callBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 100px;
        padding: 16px 23px;
        width: 173px;
        height: 51px;
        background: #506a47;
        border: none;
        text-decoration: none;
        box-sizing: border-box;
      }

      .callNow {
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        color: #e9f2d0;
      }

      .socialBox {
        width: 92px;
        height: 40px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .contentContainer {
    gap: 20px;
  }

  .secondBox {
    gap: 15px;
  }
}


@media screen and (max-width: 480px) {
  .contentContainer {
    max-height: 470px !important;
  }

  .title {
    font-size: 78px !important;
  }

  .descriptionText {
    font-size: 18px !important;
  }

  .numberText {
    font-size: 30px !important;
  }

  .nameText {
    font-size: 32px !important;
    line-height: 1.2 !important;
  }
}

@media screen and (max-height: 725px) {
  .contacteContainer {
    box-sizing: border-box;
  }

  .contentContainer {
    max-height: 350px !important;
  }

  .title {
    font-size: 55px;
  }
}

@media screen and (max-height: 500px) {
  .contacteContainer {
    box-sizing: border-box;
  }

  .contentContainer {
    flex-direction: row !important;
    max-width: 550px !important;
    max-height: 230px !important;
  }

  .title {
    font-size: 48px !important;
  }

  .secondBox {
    max-height: 180px !important;
  }

  .numberText {
    font-size: 30px !important;
  }

  .nameText {
    font-size: 32px !important;
  }
}
