.fourthResortContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  min-height: 1390px;
  position: relative;
  background: #FBF5E4;
  border-radius: 80px 80px 0 0 ;

  .contentContainer {
    min-height: 1163px;
    width: 100%;
    border-radius: 80px;
    padding: 110px 102px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    .contentWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      min-height: 338px;
      height: 100%;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 338px;
      height: 100%;

      .title {
        font-family: "Playfair Display", serif;
        font-style: italic;
        font-weight: 500;
        font-size: var(--font-size-xl);
        color: #083c00;
        z-index: 10;
        text-transform: uppercase;
        text-align: center;
      }

      .description {
        font-family: Inter, serif;
        font-weight: 400;
        font-size: 24px;
        color: #083c00;
        z-index: 10;
        text-align: center;
      }

      .callBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 100px;
        padding: 16px 23px;
        width: 173px;
        height: 51px;
        background: #506a47;
        border: none;
        text-decoration: none;
        box-sizing: border-box;
        z-index: 10;
      }

      .callNow {
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        color: #e9f2d0;
        z-index: 10;
      }
    }

    .mediaContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 80px;
    }

    .carousel {
      width: 100%;
      height: 500px;
      box-sizing: border-box;
      -webkit-user-select: none;
      user-select: none;
    }

    .image {
      height: 500px;
      min-width: 300px;
      width: 100%;
      object-fit: cover;
      border-radius: 50px;
    }

    .mapContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 500px;
      overflow: hidden;

      iframe {
        border:none!important;
      }

      .map {
        //width: 100%;
        //height: 100%;
        border-radius: 50px;
        height: calc(100% + 4px);
        min-width: calc(100% + 4px);
        margin: -2px;
      }
    }

    .customArrow {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      color: #e9f2d0;
      border-radius: 50px;
      background: #506a47;
      //border: none;
      cursor: pointer;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      border: 1px solid #E9F2D0;

      &:disabled {
        cursor: not-allowed;
        background: #6b7568;
        opacity: 0.7;
      }
    }

    .customPrevArrow {
      left: 10px;
    }

    .customNextArrow {
      right: 10px;
    }
  }
}

@media screen and (min-width: 1295px) {
  .carousel, .image, .mapContainer {
    height: 70vh !important;
  }
}

@media screen and (max-width: 1295px) {
  .fourthResortContainer {
    min-height: 1390px !important;
    box-sizing: border-box;
  }

  .contentContainer {
    padding: 100px 40px !important;
  }

  .contentWrapper {
    align-items: center;
    max-height: 420px !important;
  }

  .contentBox {
    max-height: 500px !important;
  }

  .title {
    font-weight: 500 !important;
    font-size: var(--font-size-large) !important;
    text-transform: uppercase !important;
    text-align: left;
  }

  .description {
    font-weight: 400 !important;
    font-size: 19px !important;
    align-self: start !important;
    text-align: center;
    padding: 0 50px;
  }

  .decoration {
    display: none;
  }

  .imageBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    max-height: 480px !important;
  }

  .mediaContainer {
    flex-direction: column; // Stack the carousel and map vertically on smaller screens
    align-items: center;
  }

  //.carousel {
  //  width: 100%;
  //  height: 400px !important;
  //  box-sizing: border-box;
  //}
  //
  //.image {
  //  height: 400px !important;
  //  width: 500px !important;
  //  object-fit: cover;
  //  border-radius: 45px;
  //}
}

@media screen and (max-width: 700px) {
  .fourthResortContainer {
    min-height: 1000px !important;
    height: auto !important;
  }

  .contentContainer {
    min-height: 1000px !important;
    padding: 40px 20px !important;
  }

  .contentWrapper {
    flex-direction: column;
    align-items: center;
    min-height: 370px !important;
  }

  .column {
    flex-direction: column-reverse !important;
  }

  .contentBox {
    max-width: 100% !important;
    min-height: 420px !important;
    align-items: center !important;
    gap: 20px;
  }

  .title {
    font-weight: 500 !important;
    font-size: var(--font-size-large) !important;
    text-transform: uppercase !important;
    text-align: center;
  }

  .description {
    font-weight: 400 !important;
    font-size: 19px !important;
    align-self: center !important;
    text-align: center;
  }

  .decoration {
    display: none;
  }

  .imageBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .mediaContainer {
    gap: 50px !important;
  }

  //.carousel {
  //  min-width: 400px !important;
  //  width: 100%;
  //  height: 350px !important;
  //  box-sizing: border-box;
  //}
  //
  //.image {
  //  min-width: 400px !important;
  //  height: 350px !important;
  //  width: 400px !important;
  //  object-fit: cover;
  //  border-radius: 40px;
  //}

  .carousel, .image {
    height: 400px !important;
  }
}

@media screen and (min-width: 456px) and (max-width: 525px) {
  .contentContainer {
    gap: 80px !important;
  }
}

@media screen and (max-width: 455px) {
  .fourthResortContainer {
    min-height: 1100px !important;
  }

  .contentContainer {
    min-height: 1100px !important;
    padding: 70px 15px !important;
  }

  .contentWrapper {
    flex-direction: column;
    align-items: center;
    max-height: 1000px !important;
  }

  .column {
    flex-direction: column-reverse !important;
  }

  .contentBox {
    max-width: 100% !important;
    min-height: 520px !important;
    align-items: center !important;
  }

  .title {
    font-weight: 500 !important;
    font-size: var(--font-size-medium) !important;
    text-transform: uppercase !important;
    text-align: center;
  }

  .description {
    font-weight: 400 !important;
    font-size: 18px !important;
    align-self: center !important;
    text-align: center;
  }

  .decoration {
    display: none;
  }

  .imageBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .mediaContainer {
    gap: 50px !important;
  }

  //.carousel {
  //  width: 100%;
  //  height: 300px !important;
  //  box-sizing: border-box;
  //}
  //
  //.image {
  //  height: 300px !important;
  //  width: 320px !important;
  //  object-fit: cover;
  //  border-radius: 35px;
  //}
}

