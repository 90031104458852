.landingContainer {
  position: relative;
  max-width: 100vw;
  width: 100%;
}

.blurOverlay {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 35px;
  font-family: "Playfair Display", serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(80,106,71, 1);
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease;
}

@media screen and (max-width: 900px) {
  .blurOverlay {
    height: 100dvh !important;
  }
}


