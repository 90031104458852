.resortImageContent {
  display: flex;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  min-height: 1048px;
  height: 100%;
  background-image: url("../../../../assets/img/resssort_landing.jpg");
  background-position: center;
  background-size: cover;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 1010px;
    width: 100%;
    min-height: 697px;
    height: 100%;

    .lottieContainer {
      display: flex;
      justify-content: center;

      .lottieBox {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        width: 100px;
        height: 100px;
        transition: opacity 1s ease;
        background: rgba(251, 245, 229, 0.1);
        -webkit-backdrop-filter: blur(50px);
                backdrop-filter: blur(50px);
      }

      .hidden {
        opacity: 0;
      }
    }

    .contentBox {
      padding: 0 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 208px;
      height: 100%;
      max-width: 1010px;
      width: 100%;
      box-sizing: border-box;

      .campingResortText {
        padding-top: 122px;
        display: flex;
        justify-content: center;
        font-family: "Playfair Display", serif;
        font-style: italic;
        font-weight: 500;
        font-size: 96px;
        text-transform: uppercase;
        color: #473500;
        text-align: center;
      }

      .description {
        font-family: "Playfair Display", serif;
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #473500;
        margin-top: 20px;
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .contentWrapper {
    min-height: 625px !important;
  }

  .contentBox {
    max-width: 500px !important;
    width: 100% !important;
  }

  .campingResortText {
    font-size: 77px !important;
  }

  .description {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 500px) {
  .contentBox {
    max-width: 337px !important;
    width: 100% !important;
    max-height: 225px !important;
    height: 100% !important;
  }

  .campingResortText {
    font-size: 47px !important;
  }

  .description {
    font-size: 15px !important;
  }
}
