.navbarContainer {
  max-width: 100vw;
  width: 100%;
  height: 90px; // 107px
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 1s ease, -webkit-backdrop-filter 1s ease;
  transition: backdrop-filter 1s ease, background 1s ease;
  transition: backdrop-filter 1s ease, background 1s ease, -webkit-backdrop-filter 1s ease;
  z-index: 999;

  .navbarBox {
    gap: 12px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1181px;
    width: 100%;
    height: 52px;
    box-sizing: border-box;

    .navbarElement {
      max-width: 323px;
      width: 100%;
    }

    .linkBox {
      display: flex;
      justify-content: space-between;
      max-width: 175px;
      width: 100%;
      max-height: 19px;
      height: 100%;

      .link {
        text-decoration: none;
        font-weight: 300;
        font-size: 16px;
        text-transform: capitalize;
        color: #473500;
      }
    }

    .logoWrapper {
      display: flex;
      justify-content: center;
      max-width: 323px;
      width: 100%;

      .logoBox {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 182px;
        width: 100%;
        max-height: 52px;
        height: 100%;
      }
    }

    .phoneText {
      text-wrap: nowrap;
      text-decoration: none;
      font-weight: 300;
      font-size: 16px;
      text-transform: uppercase;
      color: #473500;
    }

    .callBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 100px;
      padding: 16px 23px;
      width: 173px;
      height: 51px;
      background: #506a47;
      border: none;
      text-decoration: none;
      box-sizing: border-box;
    }

    .callNow {
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      color: #e9f2d0;
    }
  }
}

.btns {
  display: none;

  .burgerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9999;
    height: 52px;
    width: 52px;
  }

  .callIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9999;
    text-decoration: none;
  }
}

.topMenu {
  display: flex;
  justify-content: center;
  border-radius: 0 0 40px 40px;
  width: 100%;
  height: 334px; // 344px
  box-shadow: 0 1px 0 0 #cec6ab;
  background: #fbf5e5;
  position: fixed;
  top: 0;
  padding-top: 90px; // 95px
  box-sizing: border-box;

  .menuWrapper {
    max-width: 190px;
    width: 100%;
    max-height: 222px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menuBtns {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 190px;
      width: 100%;
      max-height: 172px;
      height: 100%;

      .menuBtn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Inter, serif;
        font-weight: 300;
        font-size: 25px;
        text-transform: capitalize;
        color: #473500;
        border: 0.50px solid #cec6ab;
        border-radius: 100px;
        padding: 10px 29px;
        width: 190px;
        height: 52px;
        box-sizing: border-box;
      }

      .socialBox {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .socialBtn {
          max-width: 91px;
          width: 100%;
          height: 52px;
          border-radius: 144px;
          border: 0.50px solid #cec6ab;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .phoneTextBig {
    font-family: Inter, serif;
    font-weight: 300;
    font-size: 23px;
    text-transform: uppercase;
    color: #473500;
    text-decoration: none;
  }
}

@media screen and (max-width: 900px) {
  .navbarBox {
    margin: 0 12px !important;
  }

  .navbarElement {
    display: none !important;
  }

  .btns {
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 118px;
    width: 100%;
    max-height: 52px;
    height: 100%;
  }

  .logoWrapper {
    justify-content: start !important;
    width: 182px !important;
    z-index: 9999;
  }
}
