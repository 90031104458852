.secondResortContainer {
  display: flex;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  height: 1283px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow-y: visible;
  overflow-x: clip;

  .contentContainer {
    height: 1363px;
    width: 100%;
    background: #FBF5E4;
    position: absolute;
    top: -80px;
    border-radius: 80px;
    padding: 109px 102px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-height: 370px;
      height: 100%;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 687px;
      width: 100%;
      max-height: 370px;
      height: 100%; }

      .title {
        font-family: "Playfair Display", serif;
        font-style: italic;
        font-weight: 500;
        font-size: var(--font-size-large);
        color: #083c00;
        z-index: 10;
        text-transform: uppercase;
      }

      .description {
        font-family: Inter, serif;
        font-weight: 400;
        font-size: 24px;
        color: #083c00;
        z-index: 10;
      }

      .callBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 100px;
        padding: 16px 23px;
        width: 173px;
        height: 51px;
        background: #506a47;
        border: none;
        text-decoration: none;
        box-sizing: border-box;
        z-index: 10;
      }

      .callNow {
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        color: #e9f2d0;
        z-index: 10;
      }

    .imageBox {
      max-width: 340px;
      width: 100%;
      max-height: 370px;
      height: 100%;
      position: relative;
    }
  }

  .contentBoxMobile {
    display: none;
  }
}

@media screen and (max-width: 1295px) {
  .secondResortContainer {
    max-height: 1120px !important;
  }

  .contentContainer {
    height: 1200px !important;
    padding: 30px 40px !important;
  }

  .contentWrapper {
    align-items: center;
    max-height: 650px !important;
    gap: 10px;
  }

  .contentBox {
    max-height: 480px !important;
  }

  .title {
    font-weight: 500 !important;
    font-size: var(--font-size-medium) !important;
    text-transform: uppercase !important;
    text-align: left;
  }

  .description {
    font-weight: 400 !important;
    font-size: 19px !important;
    width: 80%;
  }

  .decorationOne {
    height: 550px !important;
    width: 450px !important;
    top: -80px !important;
    left: -150px !important;
  }

  .decorationTwo {
    height: 600px !important;
    width: 500px !important;
    top: -84px !important;
    left: -65px !important;
  }

  .imageBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    max-height: 480px !important;
  }

  .image {
    position: static !important;
    width: auto !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 700px) {
  .secondResortContainer {
    min-height: 1770px !important;
  }

  .contentContainer {
    height: 1850px !important;
    padding: 30px 20px !important;
  }

  .contentWrapper {
    flex-direction: column;
    align-items: center;
    max-height: 880px !important;
  }

  .column {
    flex-direction: column-reverse !important;
  }

  .contentBox {
    max-width: 100% !important;
    max-height: 370px !important;
    align-items: center !important;
  }

  .title {
    font-weight: 500 !important;
    font-size: var(--font-size-large) !important;
    text-transform: uppercase !important;
    text-align: center;
  }

  .description {
    font-weight: 400 !important;
    font-size: 19px !important;
    align-self: center !important;
    text-align: center;
  }

  .decorationOne {
    height: 550px !important;
    width: 450px !important;
    top: -80px !important;
    left: -150px !important;
  }

  .decorationTwo {
    height: 650px !important;
    width: 550px !important;
    top: -84px !important;
    left: -65px !important;
  }

  .imageBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .image {
    position: static !important;
    width: auto !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 455px) {
  .secondResortContainer {
    min-height: 2020px !important;
  }

  .contentContainer {
    height: 2100px !important;
    padding: 30px 15px !important;
  }

  .contentWrapper {
    flex-direction: column;
    align-items: center;
    max-height: 1000px !important;
  }

  .column {
    flex-direction: column-reverse !important;
  }

  .contentBox {
    max-width: 100% !important;
    max-height: 430px !important;
    align-items: center !important;
  }

  .title {
    font-weight: 500 !important;
    font-size: var(--font-size-medium) !important;
    text-transform: uppercase !important;
    text-align: center;
  }

  .description {
    font-weight: 400 !important;
    font-size: 18px !important;
    align-self: center !important;
    text-align: center;
  }

  .decorationOne {
    height: 650px !important;
    width: 550px !important;
    top: -100px !important;
    left: -150px !important;
  }

  .decorationTwo {
    height: 650px !important;
    width: 550px !important;
    top: -84px !important;
    left: -65px !important;
  }

  .imageBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .image {
    position: static !important;
    width: auto !important;
    height: 100% !important;
  }
}
