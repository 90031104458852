@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

:root {
  --font-size-xl: 80px;
  --font-size-large: 60px;
  --font-size-medium: 52px;
  --font-size-small: 32px;
}

body {
  font-family: Inter, serif;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #506A47;
}
