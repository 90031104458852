.downbarContainer {
  border-radius: 80px 80px 0 0;
  width: 100%;
  height: 328px;
  background: #E9F2D0;
  padding: 78px 94px;
  box-sizing: border-box;

  .downbarContent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .contentBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 483px;
      width: 100%;
      min-height: 172px;
      height: 100%;

      .linkedinBox {
        display: flex;
        justify-content: space-between;

        .linkedinCard {
          border: 0.50px solid #506a47;
          border-radius: 90px;
          padding: 11px 18px;
          width: 234px;
          height: 40px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-decoration: none;

          .linkedinCardText {
            font-family: Inter, serif;
            font-weight: 300;
            font-size: 12px;
            text-align: center;
            color: #083c00;
            text-decoration: none;
          }
        }
      }

      .callBtnBox {
        max-width: 190px;
        width: 100%;
        max-height: 172px;
        height: 100%;

        .callBtn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 100px;
          padding: 16px 23px;
          width: 100%;
          height: 51px;
          background: #506a47;
          border: none;
          text-decoration: none;
          box-sizing: border-box;
        }

        .callNow {
          font-weight: 400;
          font-size: 16px;
          text-transform: uppercase;
          text-align: center;
          color :#e9f2d0;
        }
      }

      .btnBox {
        width: 190px;
        height: 172px;

        .menuBtns {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-width: 190px;
          width: 100%;
          max-height: 172px;
          height: 100%;

          .menuBtn {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Inter, serif;
            font-weight: 300;
            font-size: 25px;
            text-transform: capitalize;
            color: #083c00;
            border: 0.50px solid #506a47;
            border-radius: 100px;
            padding: 10px 29px;
            width: 190px;
            height: 52px;
            box-sizing: border-box;
          }

          .socialBox {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .socialBtn {
              max-width: 91px;
              width: 100%;
              height: 52px;
              border-radius: 144px;
              border: 0.50px solid #506a47;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.downbarContainer2 {
  display: none;
}

@media screen and (max-width: 1156px) {
  .downbarContainer {
    display: none;
  }

  .downbarContainer2 {
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 226px;
    background: #E9F2D0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;

    .leftBox {
      height: 100%;
      max-width: 173px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

        .callBtn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 100px;
          padding: 16px 23px;
          width: 100%;
          height: 51px;
          background: #506a47;
          border: none;
          text-decoration: none;
          box-sizing: border-box;
        }

        .callNow {
          font-weight: 400;
          font-size: 16px;
          text-transform: uppercase;
          text-align: center;
          color :#e9f2d0;
        }
      }

    .linkedinCard {
      border: 0.50px solid #506a47;
      border-radius: 90px;
      padding: 11px 18px;
      width: 234px;
      height: 40px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;

      .linkedinCardText {
        font-family: Inter, serif;
        font-weight: 300;
        font-size: 12px;
        text-align: center;
        color: #083c00;
        text-decoration: none;
      }
    }
    }

    .rightBox {
      height: 100%;
      max-width: 173px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .menuBtns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-height: 130px;
        height: 100%;

        .menuBtn {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Inter, serif;
          font-weight: 300;
          font-size: 20px;
          text-transform: capitalize;
          color: #083c00;
          border: 0.50px solid #506a47;
          border-radius: 100px;
          padding: 5px 29px !important;
          box-sizing: border-box;
        }

        .socialBox {
          display: flex;
          gap: 8px;
          width: 100%;

          .socialBtn {
            padding: 5px 29px !important;
            max-width: 91px;
            width: 100%;
            border-radius: 144px;
            border: 0.50px solid #506a47;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

@media screen and (max-width: 515px) {
  .downbarContainer2 {
    padding: 10px 8px !important;
  }

  .linkedinCard {
    width: 172px !important;
  }
}

@media screen and (max-width: 362px) {
  .downbarContainer2 {
    flex-direction: column;
    height: 380px;

    .leftBox {
      height: 160px;
    }

    .rightBox {
      height: 166px;

      .menuBtns {
        max-height: 115px;
      }
    }
  }
}
